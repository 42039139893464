import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"

class IndexPage extends React.Component {
  render() {
    const siteTitle = "Alexandra's Books"

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[`blog`, `books`, `alexandra`, `reviews`]}
        />
        <Link to="/blog/">
          <Button marginTop="20px">Reviews</Button>
        </Link>
        <a href="https://amazon.com/hz/wishlist/ls/1ugnldrfwgufk" target="_blank">
          <Button marginTop="20px">Amazon Wishlist</Button>
        </a>
      </Layout>
    )
  }
}

export default IndexPage
